<template>
  <v-col
    :cols="width"
    :aut-dynamic-page="definition.href"
    class="behaviour_dynamic_page"
  >
    <LoaderContent
      :value="showSkeleton"
      mode="skeleton"
      aut-dynamic-page-skeleton
    />
    <Page
      v-if="currentContext && page"
      :key="pageKey"
      :page="dynamicPage"
      :access_control="accessControl"
      :context="currentContext"
      @widget_resized="widgetResized"
    />
  </v-col>
</template>
<script>
import { pageMixin } from "@/components/mixin.js";
import { createContext } from "@/util.js";

const debug = require("debug")("atman.components.dynamic_page");
export default {
  name: "DynamicPage",
  mixins: [pageMixin],
  props: {
    definition: {
      type: Object,
    },
  },
  watch: {
    definition() {
      this.loadFlow();
    },
  },
  computed: {
    dynamicPage() {
      const page = this.page;
      const defaultClasses =
        "behavior-dynamic-page behavior-no-editor behavior-no-fav behavior-no-pin behavior-no-permission pa-0 ma-0";
      let pageClasses = page?.classes;
      if (pageClasses) {
        page.classes = page.classes + ` ${defaultClasses}`;
        return page;
      }
      page.classes = defaultClasses;
      return page;
    },
    width() {
      if (this.isSmallScreen) {
        return 12;
      }
      return this.definition.width == "auto" ? 6 : this.definition.width;
    },
    showSkeleton() {
      return (
        (!this.currentContext || !this.page) &&
        this.isFeatureEnabled("dynamic_pages.skeleton_loader")
      );
    },
  },
  mounted() {
    this.loadFlow();
  },
  methods: {
    widgetResized() {
      this.$forceUpdate();
    },
    async loadFlow() {
      const component = this;
      if (!component?.definition) {
        return;
      }
      const context = createContext(component.definition.href);
      this.currentContext = context;
      debug(`loadFlow invoked`, context);
      await component.fetchFlowDetails(component.definition.href, false);
      if (
        !this.page &&
        this.isFeatureEnabled("dynamic_pages.remove_missing_dynamic_pages")
      ) {
        const thisComponent = this.$el;
        const removeChild = thisComponent?.parentElement?.removeChild;
        if (typeof removeChild == "function") {
          thisComponent?.parentElement?.removeChild(thisComponent);
        }
      }
    },
  },
};
</script>
